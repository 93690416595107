@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-ExtraBold.ttf');
  font-weight: 800;
  font-style: 'normal';
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Bold.ttf');
  font-weight: 700;
  font-style: 'normal';
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-SemiBold.ttf');
  font-weight: 600;
  font-style: 'normal';
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Medium.ttf');
  font-weight: 500;
  font-style: 'normal';
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/opensans-regular.ttf');
  font-weight: 400;
  font-style: 'normal';
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Light.ttf');
  font-weight: 300;
  font-style: 'normal';
}